import React, { useEffect, useState } from 'react';
import Layout from '../shared/Layout/Layout';
import HeadMaker from '../HeadMaker';
import {
  Graduates,
  CareerStructure,
  Greetings,
  EmploymentStatistics,
  Teachers,
  CoachingBenefits,
  FAQWidget,
  Questions,
} from '../widgets';
import { getStats } from '../api/strapi';
import { useCoachesQuery } from '../app/queries/coaches.query';
import { useGreetingsQuery } from '../app/queries/greetings.query';
import { useCareerQuery } from '../app/queries/career.query';
import { useStudentsWorkQuery } from '../app/queries/studentsWork.query';
import { usePreviewsQuery } from '../app/queries/previews.query';
import data from '../pages-fsd/CareerPage/model/career.data';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

const leadData = {
  question: {
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
    leadPage: 'Главная',
    program: 'JS',
  },
  greeting: {
    leadPage: 'Карьерный центр',
    leadAction: 'Получить консультацию',
    leadBlock: 'Топ',
  },
};

function CareerPage() {
  const { greetingsImgs } = useGreetingsQuery();
  const { coachesData } = useCoachesQuery();
  const { studentsWorkData } = useStudentsWorkQuery();
  const { careerImgs } = useCareerQuery();
  const { previewsImg } = usePreviewsQuery();

  const [info, setInfo] = useState(data.employment.statistic);

  const mentors = data.mentors.mentors.map((mentor) => {
    mentor.image = coachesData[`coaches/${mentor.imgName}`];
    return mentor;
  });

  const graduatesStat = data.graduates.data.statistic.map((item) => {
    const updatedItem = info.find((el) => el.id === item.id);
    return {
      ...item,
      value: updatedItem.value,
      description: updatedItem.description,
    };
  });

  const list = data.careerStatements.list.map((card, index) => {
    card.bgImage = careerImgs[`career/card-${index + 1}`];
    return card;
  });

  useEffect(() => {
    const ac = new AbortController();
    const getData = async () => {
      try {
        const statData = await getStats();
        setInfo((prev) =>
          prev.map((item) => {
            const fetchedItem = statData.find((el) => el.id === item.id);
            return {
              ...item,
              value: fetchedItem?.statTitle,
              description: fetchedItem?.statSubTitle,
            };
          }),
        );
      } catch (e) {
        console.error(e);
      }
    };
    getData();
    return () => {
      ac.abort();
    };
  }, []);

  return (
    <Layout leadPage="Карьерный центр">
      <Greetings
        {...data.greetings}
        action={'Как мы помогаем'}
        buttonLink={'#advantages'}
        bg={'career'}
        theme="middlePurple"
        labelTheme={'standard'}
        image={greetingsImgs['greetings/career']}
        isProgram={false}
        extraLeadData={leadData.greeting}
        nonstandardImg={648}
      />
      <EmploymentStatistics
        {...data.employment}
        data={{
          statistic: info.filter((item) => item.id !== 5),
          research: data.employment.research,
        }}
      />
      <CareerStructure {...data.careerStatements} list={list} isCareerCenter />
      <Teachers {...data.mentors} teachers={mentors} bg={'coaches'} />
      <CoachingBenefits {...data.advantages} />
      <Graduates
        {...data.graduates}
        data={{
          statistic: graduatesStat,
          people: data.graduates.data.people,
        }}
        studentsData={previewsImg}
        studentsWorkData={studentsWorkData}
        noBg
        titleSx={{ maxWidth: 460, mb: { xs: 1, xl: 5 } }}
      />
      <FAQWidget {...data.faq} />
      <Questions {...data.questions} extraLeadData={leadData.question} />
    </Layout>
  );
}

export default CareerPage;
